import { axios } from "@/utils/api/index";

/**
 * Get all categories.
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAll = () => {
  return axios().get("/category/all");
};

/**
 * Post new category.
 * @param fr_content Category name (french).
 * @return {Promise<AxiosResponse<any>>}
 */
export const postNew = fr_content => {
  return axios().post("category/new", { fr_content: fr_content });
};

/**
 * Update category.
 * @param id Category to update ID.
 * @param fr_content New category name (french).
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = (id, fr_content) => {
  return axios().patch("category/update/", { id: id, fr_content: fr_content });
};

/**
 * Delete category.
 * @param id Category to delete ID.
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteById = id => {
  return axios().delete("category/delete/" + id);
};

export default { getAll, postNew, update, deleteById };
